<template>
    <div :class="{ 'header': isHeaderVisible }" class="full-screen-block">
        <header>
            <div class="container container_center">
                <div class="box">
                    <div class="name_and_photo">
                        <div class="box_header">
                            <img class="img_id" src="img/1.jpeg" alt="Фото для резюме">
                        </div>
                        <div class="box_header text_center">
                            <div class="text_fio">Валерия Оконешникова</div>
                            <div class="text_intro">frontend-разработчик</div>
                        </div>
                    </div>
                    <div class="box_header text_center contact_info">
                        <div class="text_intro" style="color: rgb(39, 51, 155);">
                            <div class="hoverText">
                                e-mail: <a href="mailto:leeptco@mail.ru">leeptco@mail.ru</a>
                            </div>
                            <div class="hoverText"> GitHub: <a href="https://github.com/leeptco">github.com/leeptco</a>
                            </div>
                            <div class="hoverText"> Резюме <a
                                    href="https://spb.hh.ru/resume/d9b2579dff0c5351960039ed1f46677261594e"> hh.ru</a>
                            </div>
                            <div class="hoverText"> tg: <a href="https://t.me/leeptco">leeptco</a> </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isHeaderVisible: false,
        };
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeUnmount() { // Изменено с beforeDestroy на beforeUnmount
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll() {
            this.isHeaderVisible = window.scrollY > 0;
        },
    },
};
</script>

<style scoped>
.full-screen-block {
    background-image: url(../../img/back.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    color: white;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;
    transition: 1s ease;
}

header {
    width: 80%;
}

.box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 2%;
}

@media (max-width: 700px) {
    .box {
        flex-direction: column;
        align-items:start;
        gap: 10px;
    }
}

.header {
    margin: 50px auto;
    height: 38vh;
    background-color: white;
    background: rgb(255, 255, 255);
}


.hoverText {
    transition: 1s ease;  
  }

.hoverText:hover {
    background: rgba(255, 255, 255, 0.763); 
    color: blue; 
    padding: 5px 10px ;
    border-radius: 10px;
}

.img_id {
    width: 20%;
    height: auto;
    border-radius: 10px;

    display: flex;
    align-items: center;
}



.container_center {
    display: flex;
    justify-content: center;
    width: 100%;
}


.box_header {
    margin: 10px;
}


.contact_info {
    text-align: right;
}

.text_fio {
    font-size: 160%;
    letter-spacing: 6px;
    font-weight: 700;
}

.text_intro {
    font-size:130%;
    color: rgb(143, 150, 209);
    text-align: left;
}

.create-line {
    width: 100%;
    border-top: 3px solid #27339B;
    margin-top: 50px;
    margin-bottom: 50px;
}

.project_head {
    font-size: 300%;
    letter-spacing: 6px;
    font-weight: 500;
    margin: 100px auto 50px;
}

.project_site_photo {
    height: 350px;
    width: 600px;
    object-fit: cover;
}
.margin_left {
    margin-left: 50px;
}
.margin_right {
    margin-right: 50px;
}

.project_inner {
    text-align: justify;
    font-weight: 500;
}

@media (max-width: 1020px) {
    .project_box, .reverse {
        flex-direction: column; /* Изменяем направление на вертикальное */
        align-items: center; /* Выравниваем элементы по левому краю */
        margin-bottom: 20px; /* Добавляем отступ между проектами */
    }
    .margin_left {
        margin-left: 0; /* Убираем отступ слева на маленьких экранах */
        margin-top: 30px; /* Добавляем отступ сверху для фото */
    }
    .margin_right {
        margin-right: 0; 
        margin-bottom: 30px;
    }
    .project_site_photo {
        width: 100%;
        height: auto;
       
    }
}
@media (min-width: 1700px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1600px;
    }
}
@media (min-width: 2200px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 2048px;
    }
}

footer {
    margin-bottom: 50px;
}
a {
    text-decoration: none;
    color: rgb(39, 51, 155);
}
.image-wrapper {
    position: relative; /* Устанавливаем относительное позиционирование для родителя */
}

.image-wrapper img {
    transition: transform 0.6s ease, filter 0.3s ease; 
}

.image-wrapper:hover img {
    transform: scale(1.1); /* Увеличиваем изображение на 10% */
    filter: brightness(0.7); /* Затемняем изображение */
}





</style>