<template>



<FullScreenHeader />

  <div class="container">
    <div  class="project_head" v-motion-fade
    :duration="1400">ПРОЕКТЫ</div>

    <ProjectBox
      v-for="(project, index) in projects"
      :key="index"
      :title="project.title"
      :description="project.description"
      :imageSrc="project.imageSrc"
      :link="project.link"
      :index="index"
    />
  </div>


</template>

<script>
import FullScreenHeader from './components/FullScreenHeader.vue';
import ProjectBox from './components/ProjectBox.vue'; // Путь к компоненту

export default {
  components: {
    FullScreenHeader,

    ProjectBox
  },
  data() {
    return {
      projects: [
        {
          title: "SITIM",
          description: 'Sitim - цифровая платформа, где пользователи могут приобретать онлайн-курсы по различным сферам деятельности, созданные якутскими компаниями и индивидуальными предпринимателями. Разработан в рамках хакатона "Будущее Якутии" и стал моим первым серьезным опытом в веб-разработке. Я занималась версткой сайта с использованием HTML, CSS, Bootstrap и JavaScript, а также работала с дизайном в Figma. В результате нашей работы команда заняла второе место на хакатоне.',
          link: "https://leeptco.github.io/projects/ITegel/",
          imageSrc: "img/sitim.jpeg"
        },
        {
          title: "МАТРИЦА СУДЬБЫ",
          description: 'Калькулятор матрицы судьбы вычисляет судьбоносные числа пользователей на основе их даты рождения. Каждый элемент матрицы рассчитывается по уникальным формулам. Я занималась не только дизайном и обрисовкой SVG-элементов, а также версткой на платформе Tilda с использованием Zeroblock. Интеграция HTML, CSS/SCSS и JavaScript кода обеспечила необходимую функциональность, чтобы создать полноценное интерактивное веб-приложение.',
          link: "https://astasynum.ru/calculator_matrix",
          imageSrc: "img/matrix.png"
        },
        {
          title: "MASTER-KLASS.RU",
          description: 'Веб-разработка сайта для обеспечения записи на мастер-классы. Информационная система, позволяет пользователям просматривать актуальные мастер-классы, регистрироваться и авторизироваться на сайте. Пользователи могут войти как покупатели, или как организаторы мастер-классов. Реализованы функции создания, редактирования и удаления карточек мероприятий. Проект разработан в ходе выполнения моей дипломной работы и представляет собой полноценное веб-приложение, написан с помощью PHP, HTML, CSS, JS, Bootstrap, в качестве СУБД использовалась MySQL.',
          link: "http://leeptco.ru/projects/diplom/index.php",
          imageSrc: "img/mk.png"
        },
        {
          title: "ANNONCE",
          description: 'Верстка сайта для магазина одежды Annonce. Был реализован слайдер с помощью swiper.js. Есть возможность добавить товар в раздел "Избранное" (изменяется заливка иконки fav и появляется сообщение с подтверждением на странице). Разработана одна страница, тестовое задание от CSG. Стек: HTML, CSS/SCSS, JS, SwiperJS.',
          link: "http://leeptco.ru/projects/annonce",
          imageSrc: "img/annonce.png"
        },
        {
          title: "SPEKTRUM",
          description: 'Spektrum - платформа для автоматического определения тематики страницы по её содержанию. Пользователь вводит URL-адрес, и система определяет основную тему сайта.                      Сайт выполнен во время участия в хакатоне от Kokos Group. Основная функция по определнию тематики до конца не реализована, при любом содержании поля ввода после нажатия кнопки "далее" - выводится страница с тематикой "строительство/обустройство/ремонт" для наглядности. Несмотря на то что проект не завершен, он стал ценным опытом в области веб-разработки и работы с API.',
          link: "https://leeptco.github.io/projects/kokos",
          imageSrc: "img/spektrum.png"
        },
        {
          title: "ПРЕВЬЮ ALEF",
          description: 'Разработка страниц личного кабинета для ввода информации о пользователе и его детях с использованием HTML, CSS, SCSS и Vue 3. Пользователь может заполнить свои ФИО и возраст, а также добавить информацию о детях, нажав на кнопку "+", которая открывает поля для ввода имени и возраста ребенка. Максимально можно добавить до пяти детей, после чего кнопка "+" становится недоступной. Каждая запись о ребенке имеет кнопку "удалить", позволяющую удалять соответствующие записи. При нажатии на кнопку "Сохранить" введенные данные отображаются на странице "Превью".',
          link: "https://leeptco.github.io/projects/alef",
          imageSrc: "img/alef.png"
        },
        {
          title: "EBEELERA",
          description: 'Веб-разработка сайта для вязаных изделий ручной работы Сайт-каталог обеспечивает удобный просмотр и приобретение товаров  локального бренда вязаной одежды.              Разработан с целью продвижения моего собственного бренда вязаных изделий. Я создала интуитивно  понятный интерфейс, который позволяет пользователям легко находить и просматривать товары в наличии. Стек: HTML, CSS, Bootstrap',
          link: "https://leeptco.github.io/projects/ebeelera",
          imageSrc: "img/ebeelera.png"
        },
        {
          title: "IMPERIA FORUM",
          description: 'Верстка памятки участника для 33-й международной продовольственной выставки от PETERFOOD. Стек: HTML, CSS/SCSS',
          link: "http://leeptco.ru/projects/imperia_forum_guide",
          imageSrc: "img/imperia_forum.png"
        },
      ]
    };
  }
}
</script>

<style>
.container {
  display: flex;
  flex-direction: column;
}

.hoverable-text {
  font-size: 16px;
  transition: font-size 0.3s ease, background-color 0.3s ease; 
}


</style>