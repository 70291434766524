<template>
  <div class="create-line-vue"></div>
  <div v-motion :initial="{ opacity: 0, y: 100 }" :visibleOnce="{ opacity: 1, y: 0 }" :duration="1200">
    <div class="container">
      <div class="box project_box" :class="{ 'reverse': index % 2 !== 0 }">
        <div class="margin_right" v-if="index % 2 === 0">
          <a  :href="link">
          <div class="image-wrapper project_site_photo">
            <img :src="imageSrc" alt="Описание изображения" class="img-fluid" />
          </div>
        </a>
        </div>
        <div class="margin_left" v-else>
          <a :href="link">
          <div class="image-wrapper project_site_photo">
            <img :src="imageSrc" alt="Описание изображения" class="img-fluid" />
          </div>
        </a>
        </div>

        <div class="project_text">
          <div class="project_name">{{ title }}</div>
          <div class="project_inner">{{ description }}</div>
        </div>
        
      </div>
    </div>
  </div>


</template>

<script>




export default {
  props: {
    title: String,
    description: String,
    imageSrc: String,
    link: String,
    index: Number
  }
}
</script>

<style>
.container {
  display: flex;
}

.project_box {
  display: flex;
  align-items: center;
  margin-bottom: 20px; 
}

.margin_right {
  margin-right: 20px; 
}

.margin_left {
  margin-left: 20px;
}

.reverse {
  flex-direction: row-reverse;
}
@media (max-width: 1020px) {
    .reverse {
        flex-direction: column; 
        align-items: center; 
        gap: 20px;
    }}
    .create-line-vue {
    width: 100%;
      border-top: 2px solid #8F96D1;
      margin-top: 70px;
      margin-bottom: 70px;
  }
  .project_text {
    width: 100%;
}
.img-fluid {
    border-radius: 10px;
    box-shadow: -10px 11px 2px 1px rgba(0, 0, 0, 0.1);
}

* {
    color: rgb(39, 51, 155);
    font-family: Montserrat;
}



.container_center {
    display: flex;
    justify-content: center;
    width: 100%;
}

.box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 2%;
}

.box_header {
    margin: 10px;
}

.text_center {
    text-align: left;
}

.contact_info {
    text-align: right;
}

.text_fio {
    font-size: 160%;
    letter-spacing: 6px;
    font-weight: 700;
}

.text_intro {
    font-size:130%;
    color: rgb(143, 150, 209);
    text-align: left;
}

.create-line {
    width: 100%;
    border-top: 3px solid #27339B;
    margin-top: 50px;
    margin-bottom: 50px;
}
.create-line1 {
    border-top: 2px solid #8F96D1;
    margin-top: 70px;
    margin-bottom: 70px;
}

.project_head {
    font-size: 300%;
    letter-spacing: 6px;
    font-weight: 500;
    margin: 100px auto 50px;
}

.project_name {
    font-size: 200%;
    font-weight: 700;
    margin: 15px;
}

.project_site_photo {
    height: 350px;
width: 600px;
    object-fit: cover;
}
.margin_left {
    margin-left: 50px;
}
.margin_right {
    margin-right: 50px;
}

.project_inner {
    text-align: justify;
    font-weight: 500;
}

@media (max-width: 1020px) {
    .project_box, .reverse {
        flex-direction: column; /* Изменяем направление на вертикальное */
        align-items: center; /* Выравниваем элементы по левому краю */
        margin-bottom: 20px; /* Добавляем отступ между проектами */
    }
    .margin_left {
        margin-left: 0; /* Убираем отступ слева на маленьких экранах */
        margin-top: 30px; /* Добавляем отступ сверху для фото */
    }
    .margin_right {
        margin-right: 0; 
        margin-bottom: 30px;
    }
    .project_name {
        text-align: center;
    }
    .project_site_photo {
        width: 100%;
        height: auto;
    object-fit: cover; 
    }
}
@media (min-width: 1700px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1600px;
    }
}
@media (min-width: 2200px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 2048px;
    }
}

footer {
    margin-bottom: 50px;
}
a {
    text-decoration: none;
    color: rgb(39, 51, 155);
}
.image-wrapper {
    position: relative; /* Устанавливаем относительное позиционирование для родителя */
}

.image-wrapper img {
    transition: transform 0.6s ease, filter 0.3s ease; 
}

.image-wrapper:hover img {
    transform: scale(1.1); /* Увеличиваем изображение на 10% */
    filter: brightness(0.7); /* Затемняем изображение */
}




</style>